// Here you can add other styles
.sidebar {
  .nav-icon{
    display:none !important;
  }
}

.main {
  .container-fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .app-footer {
    z-index: 2;
  }
  padding-bottom: $footer-height;
}

.footer-fixed {
  .app-body {
    margin-bottom: inherit;
  }
}

.comments {
  input {
    background-color: transparent !important;
    font-size: 12.8px !important;
    border-radius: 0;
    border-top:0;
    border-right: 0;
    border-left:0;
    padding:0;
    border-bottom: 1px solid $border-color !important;
    height: 25px;
    //margin-top: -7px !important;
    &::placeholder {
      color: $text-muted;
    }

    &:disabled{
      border: 0 !important;
    }
  }

  .icon-pencil{
    position: absolute;
    display: none;
    color: $input-color;
    bottom: calc((#{$input-height} - 14px)/2);
    left: -3px;
  }

}

.observations {
  /*padding-top: 9.5px;
  padding-bottom: 9.5px;*/
}

button:focus {
  outline: none;
}

select {
  -webkit-appearance:none;
}

/*.task-actions{
  .btn{
    pointer-events: none;
  }
}*/

@mixin custom-card-accent-variant($color) {
  border-top-width: 5px !important;
}

@each $color, $value in $theme-colors {
  .card-accent-#{$color} {
    @include custom-card-accent-variant($value);
  }
}

